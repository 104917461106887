.h1-staff {
  background-image: url(../images/staff/h1.jpg);
}

.bg-image {
  &-construction {
    background-image: url(../images/staff/department-construction.jpg);
  }
  &-sales {
    background-image: url(../images/staff/department-sales.jpg);
  }
  &-management {
    background-image: url(../images/staff/department-management.jpg);
  }
}

.department {
  &-button {
    display: inline-block;
    width: 100%;
    color: $color-black;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    &-wrapper {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 40px;
        
        @include view-at(sp) {
          margin-bottom: 20px;
        }
      }
    }

    &-mask {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 120px 50px;
      background-color: rgba(255, 255, 255, .3);

      @include view-at(sp) {
        padding: 20px;
      }

      &--bottom {
        padding: 50px 20px;

        @include view-at(sp) {
          padding: 20px;
        }
      }
    }

    &-text {
      font-size: rem(30px);
      font-weight: $bold;
      margin-bottom: rem(40px);

      @include view-at(sp) {
        font-size: rem(24px);
        margin-bottom: rem(15px);
      }
    }

    .c-button {
      background-color: transparent;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.detail {
  &-mv {
    display: inline-block;
    width: 40%;
    padding: 45px 40px;
    font-size: rem(30px);
    font-weight: $bold;
    line-height: 1;
    text-align: center;
    background-color: rgba(255, 255, 255, .5);

    @include view-at(sp) {
      width: 100%;
      padding: 25px 20px;
    }

    &-wrapper {
      text-align: center;
      padding: 100px 40px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;

      @include view-at(sp) {
        padding: 40px 30px;
      }
    }
  }

  &-member {
    border-radius: 50%;
  }
}