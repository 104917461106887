@charset "UTF-8";

.top {
    &-mv {
        position: relative;
        overflow: hidden;

        @include view-at(tab) {
            margin-top: 50px;
        }

        &-inner {
            max-width: 100%;
            margin: 0 auto;

            div {
                @include view-at(sp) {
                    position: relative;
                    width: 100%;
                    height: 250px;
                    overflow: hidden;

                    img {
                        width: auto;
                        height: auto;
                        object-fit: cover;
                        object-position: center center;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%) translateY(-50%);
                        min-width: 100%;
                        min-height: 100%;
                    }
                }
            }
        }

        &-text {
            width: 60%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            @include view-at(sp) {
                width: 90%;
            }
        }

        +.p-gnav {
            margin-top: 0;
        }

        /* .slick-track img {
            @include view-at(sp) {
                width: 100%;
                height: 250px;
                object-fit: cover;
            }
        } */
    }

    &-news {
        &-title {
            font-size: rem(32px);
            line-height: 1.2;
            text-align: center;

            span {
                color: $color-gray-dark;
                font-size: rem(20px);
            }
        }

        &-list {
            margin-bottom: 40px;

            li {
                line-height: rem(24px);
            }

            a {
                color: $color-black;
            }
        }
    }

    &-panel {
        height: 100%;

        &-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            text-decoration: none;
            color: $color-white;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        &-button {
            padding: 120px 40px;

            @include view-at(sp) {
                padding: 40px 20px;
            }

            &-text {
                @include font-sawarabi_gothic;
                font-size: rem(30px);
                font-weight: $bold;
                margin-bottom: rem(40px);

                @include view-at(tab) {
                    font-size: rem(24px);
                    margin-bottom: rem(15px);
                }
            }

            .c-button:hover {
                opacity: 1;
            }
        }

        &-text {
            padding: 80px 120px;

            @include view-at(mdpi) {
                padding: 80px;
            }

            @include view-at(laptop) {
                padding: 60px;
            }

            @include view-at(sp) {
                padding: 30px 20px;
            }

            &-bg {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                max-width: 600px;
                text-align: center;
                padding: 40px 20px;

                @include view-at(sp) {
                    min-width: 318px;
                    padding: 20px 10px;
                }

                &--green {
                    background-color: rgba(159, 221, 27, .5);
                }
                &--orange {
                    background-color: rgba(255, 137, 0, .5);
                }

                .c-button:hover {
                    opacity: 1;
                }

                // p {
                //     @include font-sawarabi_gothic;
                //     font-size: rem(30px);
                //     line-height: 1;
                //     text-align: center;

                //     @include view-at(tab) {
                //         font-size: rem(24px);
                //     }
                // }

                // span {
                //     font-size: rem(40px);

                //     @include view-at(tab) {
                //         font-size: rem(32px);
                //     }
                // }
            }
        }
    }
}

.bg-image {
    &-service {
        background-image: url(../images/top/panel-service.jpg);
    }
    &-technology {
        background-image: url(../images/top/panel-technology.jpg);
    }
    &-work {
        background-image: url(../images/top/panel-work.jpg);
    }
    &-staff {
        background-image: url(../images/top/panel-staff.jpg);
    }
    &-recruit {
        background-image: url(../images/top/panel-recruit.jpg);
    }
  }
