
/* --------------------------
color
-------------------------- */

$color-text: #333333;
$color-text-light: #B1B1B1;

$color-red: #f80d0d;
$color-red-dark: #a40000;
$color-red-light: #f8ebed;
$color-blue: #1f4fdd;
$color-blue-dark: #0418c0;
$color-navy: #093361;
$color-blue-snow: rgba(31, 79, 221, .2);
$color-blue-light: rgba(31, 79, 221, .5);
$color-orange: #ff8900;
$color-green: #9fdd1b;
$color-green-light: #f2faed;
$color-green-dark: #4e8826;
$color-white: #fff;
$color-black: #000;

$color-gray: #ccc;
$color-gray-light: #eee;
$color-gray-dark: #666666;
$color-gray-snow: #F5F5F5;
$color-border: #707070;

/* --------------------------
spacing
-------------------------- */
$space: 80px, 80px, 40px, 20px;
$space-tab: 80px, 80px, 40px, 15px;
$space-sp: 40px, 40px, 35px, 10px;

/* --------------------------
font
-------------------------- */
$font-size: 3.33rem, 2rem, 1.6rem, 1.2rem, 1rem, 0.875rem;
$line-height: 1.5, 1.5, 1.5, 1.5, 1.5, 1.5;

$font-size-sp: 3.33rem, 2rem, 1.6rem, 1.2rem, 1rem, 0.875rem;
$line-height-sp: 1.5, 1.5, 1.5, 1.5, 1.5, 1.5;

$bold: 700;
$medium: 500;
$regular: 400;
$light: 300;
$normal: 400;

/* --------------------------
contents width  
-------------------------- */
$contents-width: 1220px;
$contents-middle-width: 1040px;
$contents-narrow-width: 790px;
$contents-padding: 40px;
$contents-padding-sp: 20px;

/* --------------------------
z-index order
-------------------------- */
$z: (
  contents,
  arrow,
  gnav,
  leftbar,
  menu,
  header,
  footer,
  modal
);
