/* --------------------------
エクストラナビ（Gナビとは別にヘッダー内に表示するナビゲーション）
-------------------------- */

.p-exnav {
  display: flex;
  justify-content: flex-end;
  margin: 11px 0;

  @include view-at(tab) {
    display: none;
  }

  li {
    margin-left: 18px;

    a {
      color: $color-text;
      font-size: rem(11px);
      line-height: rem(19px);
      font-weight: $bold;
      text-decoration: none;

      &::after {
        content: '＞';
        display: inline-block;
        margin-left: 6px;
        font-size: rem(8px);
        position: relative;
        top: -1px;
      }
    }
  }
}
