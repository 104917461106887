.contact {
  &-tel {
    text-align: center;

    a {
      color: $color-black;
      font-size: rem(38px);
      font-weight: $bold;
      text-decoration: none;

      @include view-at(sp) {
        font-size: rem(34px);
      }
    }

    &-text {
      text-align: center;
      margin-top: 16px;
    }
  }

  &-table {
    margin: 60px auto;

    @include view-at(sp) {
      margin: 40px auto;
      border-top: 1px solid;
    }

    tr {
      th {
        padding: 20px;
        background-color: $color-blue-snow;

        @include view-at(sp) {
          display: block;
          width: 100%;
          text-align: center;
          padding: 10px;
          border-top: none;
        }
      }
      
      td {
        padding: 20px;

        @include view-at(sp) {
          display: block;
          width: 100%;
          padding: 15px;
          border-top: none;
        }
      }
    }
  }
}
