/* --------------------------
ハンバーガーメニューアイコン
-------------------------- */

.c-menu {
  display: none;

  @include view-at(tab) {
      position: absolute;
      right: 12px;
      top: 15px;
      display: block;
      padding: 0;
      z-index: index($z, menu);
  }

  &-trigger,
  &-trigger span {
    display: block;
    transition: all .2s;
    box-sizing: border-box;
  }

  &-trigger {
    position: relative;
    width: 26px;
    height: 25px;

    &-label {
      display: block;
      font-size: 8px;
      letter-spacing: 0;
      position: absolute;
      left: 0;
      right: 0;
      top: 17px;
      margin: auto;
      text-align: center;
      width: 100%;
      background-color: transparent;
      height: auto;
      color: $color-text;
    }
  }

  &-trigger span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $color-text;
    border-radius: 4px;
  }

  &-trigger span:nth-of-type(1) {
    top: 0;
  }

  &-trigger span:nth-of-type(2) {
    top: 6px;
  }

  &-trigger span:nth-of-type(3) {
    top: 12px;
  }

  &-trigger.active span:nth-of-type(1) {
    width: 26px;
    -webkit-transform: translateY(6px) translateX(-1px) rotate(-45deg);
    transform: translateY(6px) translateX(-1px) rotate(-45deg);
  }

  &-trigger.active span:nth-of-type(2) {
    opacity: 0;
  }

  &-trigger.active span:nth-of-type(3) {
    width: 26px;
    -webkit-transform: translateY(-6px) translateX(-1px) rotate(45deg);
    transform: translateY(-6px) translateX(-1px) rotate(45deg);
  }
}
