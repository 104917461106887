/* --------------------------
Sawarabi Gothic
-------------------------- */
@mixin font-sawarabi_gothic {
    font-family: "Sawarabi Gothic";
    font-style: normal;
}

/* --------------------------
mplus
-------------------------- */
@mixin font-mplus {
    font-family: 'M PLUS Rounded 1c', sans-serif;
    font-style: normal;
}

/* --------------------------
mplus
-------------------------- */
@mixin font-noto_sans {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
}
