/* --------------------------
Gナビ
-------------------------- */

.p-gnav {
  background-color: $color-blue;
  position: relative;

  @include view-at(tab) {
    display: none;
    margin: 0;
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    padding: 0 80px;
    z-index: 1000;
    height: auto;

    &.visible {
      display: block;
    }
  }

  @include view-at(tab) {
    padding: 0;
  }

  &-list {
    max-width: 1140px;
    margin: 0 auto;
    display: flex;

    @include view-at(tab) {
      flex-wrap: wrap;
      min-width: auto;
      width: 100%;
    }

    &-item {
      width: 100%;
      height: 120px;

      @include view-at(tab) {
        width: 25%;
        height: auto;
      }

      @include view-at(sp) {
        width: 50%;
      }

      &.current {
        >a {
          background-color: rgba(255, 255, 255, .9);
          color: $color-black;
          letter-spacing: -1px;
          opacity: 1;
          pointer-events: none;

          &::after {
            display: block;
            content: '';
            width: calc(100% - 8px);
            height: 4px;
            background-color: $color-orange;
            position: absolute;
            left: 4px;
            bottom: 4px;
          }
        }
      }

      @include view-at(pc) {
        &:hover {
          >a {
            background-color: rgba(255, 255, 255, .7);
            color: $color-gray-dark;
            opacity: 1;

            &::after {
              display: block;
              content: '';
              width: calc(100% - 8px);
              height: 4px;
              background-color: $color-orange;
              position: absolute;
              left: 4px;
              bottom: 4px;
            }
          }

          .p-gnav-sub {
            visibility: visible;
            opacity: 1;
            transition-delay: 0s;
          }
        }
      }

      >a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: $color-white;
        @include font-mplus;
        font-size: rem(18px);
        font-weight: $normal;
        line-height: 1.5;
        letter-spacing: -0.5px;
        padding: 4px;
        position: relative;
        text-decoration: none;
        text-align: center;

        @include view-at(tab) {
          border: 0;
          text-align: left;
          padding: 19px 15px;
        }

        @include view-at(tab) {
          font-size: rem(14px);
          letter-spacing: normal;
        }
      }
    }
  }
}
