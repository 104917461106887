/* --------------------------
背景色
-------------------------- */

.u-bg-light {
  background-color: $color-gray-snow;
}

.u-bg-white {
  background-color: $color-white;
}

.u-bg-green {
  background-color: $color-green-light;
}

.u-bg-blue {
  background-color: $color-blue;
}

.u-bg-blue-light {
  background-color: $color-blue-light;
}

.u-bg-blue-dark {
  background-color: $color-blue-dark;
}

.u-bg-red {
  background-color: $color-red-light;
}

.u-bg-orange {
  background-color: $color-orange;
}

.u-bg-image {
  background: transparent url(../images/common/bg-leaf.png) center top repeat;
}