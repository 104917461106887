html {
  font-size: 16px;
}

body {
  font-family: "Noto Sans JP", "游ゴシック体", YuGothic, "Yu Gothic M", "游ゴシック Medium", "Yu Gothic Medium", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN W3", HiraKakuProN-W3, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, sans-serif;
  font-weight: 300;
  color: $color-text;
  font-size: rem(16px);
  letter-spacing: 0.05em;
}

a {
  color: $color-blue;
  text-decoration: none;

  &:hover {
    opacity: 0.6;
  }
}

p {
  margin-bottom: 1em;
  line-height: rem(26px);

  &:last-child {
    margin-bottom: 0;
  }

  @include view-at(sp) {
    line-height: nth($line-height-sp, 5);
  }
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
}

/* .main {
  padding-top: 153px;

  @include view-at(sp) {
    padding-top: 60px;
  }
} */
