
.news-content {
  h2 {
    @extend .c-h2;
  }
  h3 {
    @extend .c-h3;
  }
  h4 {
    @extend .c-h4;
  }
  h5 {
    @extend .c-h5;
  }
  h6 {
    @extend .c-h6;
  }

  ul {
    @extend .c-list;
    @extend .c-list--point;
  }

  ol {
    @extend .c-list;
    @extend .c-list--order;
  }

  p {
    line-height: 1.625em;
  }
}

.wp-block {
  max-width: 100%;
}

.wp-block-image,
.wp-block-columns,
.wp-block-media-text,
.wp-block-table,
.wp-block-cover,
.wp-block-quote,
.wp-block-separator,
.wp-block-button {
  margin: nth($space, 3) 0;
}

.wp-block-quote {
  padding: 20px;
  background-color: $color-gray-snow;
}

.wp-block-image {
  &::after {
    content: "";
    clear: both;
    font-size: 0;
    height: 0;
    display: block;
    visibility: hidden;
  }
}

.wp-block-table {
  &.aligncenter,
  &.alignleft,
  &.alignright {
    width: 100%;
  }
}

@include view-at(tab) {
  .wp-block-columns {
    flex-wrap: nowrap;
  }
}

@include view-at(sp) {
  .wp-block-column + .wp-block-column {
    margin-top: nth($space, 2);
  }
}

.wp-block-file__button,
.wp-block-button__link {
  @extend .c-button;
}