/* --------------------------
テーブル
-------------------------- */

.c-table {
  $border-color: #707070;

  width: 100%;
  line-height: 1.5;

  thead {
    tr {
      th {
        padding: 10px;
      }
    }
  }

  tbody {
    th {
      padding: 10px;
      word-break: keep-all;
      font-weight: $normal;
      vertical-align: top; 
      width: 25%;
      border: 1px solid;

      @include view-at(sp) {
        width: auto;
      }
    }

    td {
      padding: 10px;
      border: 1px solid;
    }
  }

  &--border {
    border-collapse: collapse;

    thead {
      tr {
        border-bottom: 1px solid $border-color;

        th {
          font-weight: $bold;
          padding: 20px 30px 20px 0;
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid $border-color;
      }

      th {
        padding: 20px 0;
      }
      td {
        padding: 20px 0 20px 30px;
      }
    }
  }
}
