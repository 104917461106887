/* --------------------------
見出し
-------------------------- */

.c-h1 {
  width: 450px;
  color: $color-black;
  @include font-sawarabi_gothic;
  font-size: rem(32px);
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  padding: 50px 0;
  background-color: rgba(255, 255, 255, .5);

  @include view-at(tab) {
    padding: 30px 0;
  }

  @include view-at(sp) {
    width: 75%;
    font-size: rem(24px);
    padding: 20px 0;
  }

  span {
    color: $color-gray-dark;
    font-size: rem(20px);

    @include view-at(sp) {
      font-size: rem(16px);
    }
  }

  &-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    height: 100%;
    padding: 80px 0;
    margin-top: 4px;
    margin-bottom: 10px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    @include view-at(tab) {
      margin-top: 50px;
    }

    @include view-at(sp) {
      padding: 50px 0;
    }
  }
  &-bg {
    background-image: url(../images/common/h1-bg.jpg);
  }
  
}

.c-h2 {
  position: relative;
  color: $color-black;
  font-size: rem(24px);
  font-weight: $bold;
  letter-spacing: normal;
  line-height: 1.5;
  border-bottom: 1px solid $color-blue;
  margin-bottom: 45px;

  @include view-at(sp) {
    font-size: rem(20px);
    margin-bottom: 30px;
  }

  &::before {
    position: absolute;
    display: inline-block;
    content: '';
    bottom: -7px;
    width: 100%;
    height: 4px;
    background-color: $color-green;
  }
}

.c-h3 {
  color: $color-black;
  font-size: rem(20px);
  font-weight: $bold;
  letter-spacing: normal;
  line-height: 1.7;
  margin-bottom: 40px;
  border-bottom: 4px solid $color-gray-dark;

  @include view-at(sp) {
    font-size: rem(18px);
    margin-bottom: 25px;
  }
}

.c-h4 {
  color: $color-black;
  font-size: rem(18px);
  font-weight: $bold;
  line-height: 1.7;
  margin-bottom: 10px;
  display: inline-block;
  letter-spacing: normal;

  @include view-at(sp) {
    font-size: rem(16px);
  }
}

.c-h5 {
  font-size: nth($font-size, 5);
  line-height: nth($line-height, 5);
  letter-spacing: 0.08em;
  font-weight: $medium;
  margin-bottom: 5px;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 5);
    line-height: nth($line-height-sp, 5);
  }
}

.c-h6 {
  font-size: nth($font-size, 6);
  line-height: nth($line-height, 6);
  letter-spacing: 0.08em;
  font-weight: $medium;
  margin-bottom: 5px;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 6);
    line-height: nth($line-height-sp, 6);
  }
}

.c-h--center-line {
  @include center-line();
  margin-bottom: 5px;
}