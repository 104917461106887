.p-main {
  background-color: $color-green-light;
  padding: 60px 0;

  @include view-at(sp) {
    padding: 40px 0;
  }

  &-container {
    display: flex;
    align-items: flex-start;

    @include view-at(sp) {
      display: block;
    }
  }

  &-contents {
    background-color: $color-white;
    padding: 0 30px;
    flex-grow: 1;

    @include view-at(sp) {
      padding: 1px 20px;
    }
  }


}