/* --------------------------
テキスト装飾
-------------------------- */

.u-text-accent {
  color: $color-blue;
}

.u-text-important {
  color: $color-red;
}

.u-text-inverse {
  color: $color-white;
}

.u-text-sub {
  color: $color-gray;
}

.u-text-serif {
  font-family: 'Times New Roman', Times, serif
}

.u-text-bold {
  font-weight: $bold;
}

.u-text-normal {
  font-weight: $normal;
}

.u-text-highlight {
  background: linear-gradient(transparent 70%, $color-blue 70%);
}

.u-text-underline {
  text-decoration: underline;
}

.u-text-x-large {
  font-size: rem(28px);
  line-height: rem(42px);

  @include view-at(sp) {
    font-size: rem(28px);
    line-height: rem(42px);
  }
}

.u-text-large {
  font-size: rem(22px);
  line-height: rem(33px);
}

.u-text-bit-large {
  font-size: rem(18px);
  line-height: rem(27px);

  @include view-at(sp) {
    font-size: rem(20px);
  }
}

.u-text-medium {
  font-size: rem(18px);
  line-height: rem(27px);

  @include view-at(tab) {
    font-size: rem(16px);
    line-height: rem(25px);
  }
}

.u-text-default {
  font-size: rem(16px);
  line-height: rem(22.5px);

  @include view-at(tab) {
    font-size: rem(16px);
    line-height: rem(22.5px);
  }
}

.u-text-small {
  font-size: rem(14px);
  line-height: rem(25px);

  @include view-at(tab) {
    font-size: rem(13px);
    line-height: rem(24px);
  }

  &--tab {
    @include view-at(tab) {
      font-size: rem(13px);
      line-height: rem(24px);
    }
  }
}

.u-text-x-small {
  font-size: rem(12px);
  line-height: rem(18px);

  @include view-at(sp) {
    font-size: rem(12px);
    line-height: rem(18px);
  }

  &--sp {
    @include view-at(sp) {
      font-size: rem(12px);
      line-height: rem(18px);
    }
  }
}
