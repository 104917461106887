/* --------------------------
パンくずナビ
-------------------------- */

.c-breadcrumb {
  
  &-list {
    list-style: none;
    margin: 0;
    padding: 8.5px 0;
    display: flex;
    margin-bottom: 40px;

    @include view-at(sp) {
      flex-wrap: wrap;
      margin-bottom: 20px;
    }

    li {
      color: $color-text;
      font-size: rem(14px);

      @include view-at(sp) {
        line-height: 1.4;
      }

      &::after {
        content: '>';
        padding-right: 0.2em;
      }
      
      &:last-child {
        &::after {
          content: '';
        }
      }

      a {
        color: $color-text;
      }
    }
  }
}
