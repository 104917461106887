@charset "UTF-8";

.company {
    &-philosophy {
        &-copy {
            background-color: #eeeef2;
            margin-bottom: 40px !important;

            &-text {
                display: flex;
                justify-content: center;
                align-items: center;

                p {
                    font-size: rem(30px);
                    font-weight: $bold;
                    line-height: 2;
                    text-align: center;

                    @include view-at(sp) {
                        font-size: rem(24px);
                        line-height: 1.5;
                        padding: 20px;
                    }
                }
            }
        }

        &-bg {
            width: 100%;
            background-image: url(../images/company/philosophy-bg.jpg);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            text-align: center;
            padding: 80px 40px;
            margin-bottom: 40px;
    
            @include view-at(sp) {
                padding: 30px 20px;
            }

            &-text {
                display: inline-block;
                color: $color-white;
                font-size: rem(24px);
                font-weight: $bold;
                line-height: 2;
                text-align: center;
                padding: 30px 40px;
                background-color: rgba(255, 137, 0, .5);
    
                @include view-at(sp) {
                    font-size: rem(20px);
                    line-height: 1.5;
                    text-align: left;
                    padding: 20px;
                }
            }
        }
    }

    &-table {
        &-caption {
            color: $color-border;
            font-size: rem(14px);
            padding: 20px 0;
        }
    }

    &-map {
        iframe {
            width: 100%;
            height: 600px;

            @include view-at(sp) {
                height: 450px;
            }
        }
    }
}
