.h1-works {
    background-image: url(../images/works/h1.jpg);
  }

.works {
    &-category {
        margin-bottom: 60px;

        @include view-at(sp) {
            margin-bottom: 40px;
        }

        &-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        &-items {
            position: relative;
            margin: 0 20px;

            @include view-at(sp) {
                width: 29%;
                margin: 3% 2%;
            }

            a {
                display: inline-block;
                width: 100%;
                color: $color-text-light;
                text-align: center;
                padding-bottom: 20px;

                @include view-at(sp) {
                    padding-bottom: 10px;
                }

                &::before {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    display: inline-block;
                    content: '';
                    width: 100%;
                    border-bottom: 5px solid;
                }
            }
        }

        &--select {
            a {
                color: $color-text;
            }
        }
    }

    &-list {
        width: 100%;

        @include view-at(tab) {
            margin: 0 auto;
        }

        &-items {
            position: relative;
            width: 100%;
            max-width: 285px;
            padding: 5px;

            &:hover {
                .works-list-detail {
                    background-color: rgba(255, 255, 255, .7);
                    opacity: 1;
                }
            }

            img {
                width: 100%;
                max-width: 100%;
                height: auto;
            }
        }

        &-detail {
            position: absolute;
            left: 5px;
            bottom: 5px;
            width: calc( 100% - 10px );
            padding: 5px 10px;
            transition: all .3s;
            opacity: 0;

            p {
                color: $color-text;
                margin-bottom: 0;
            }

            &--technology {
                font-size: 14px;
            }
        }
    }
}

// lightboxの上書き
.lb-nav a.lb-prev {
    background-image: url(../images/plugins/prev.png);
}

.lb-nav a.lb-next {
    background-image: url(../images/plugins/next.png);
}

.lb-data .lb-close {
    background-image: url(../images/plugins/close.png);
}

.lb-cancel {
    background-image: url(../images/plugins/loading.gif);
}