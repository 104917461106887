/* --------------------------
ラベル
-------------------------- */

.c-label {
  display: inline-block;
  padding: 5px;
  margin-right: 10px;
  text-align: center;
  width: 90px;
  font-size: rem(15px);
  line-height: 1;
  vertical-align: bottom;
  background-color: $color-gray;
  color: $color-white;
  font-weight: $bold;

  &--notice {
    background-color: $color-blue;
  }
  &--important {
    background-color: $color-red;
  }
  &--seminar {
    background-color: $color-green;
  }
  &--recruit {
    background-color: $color-orange;
  }
}
