/* --------------------------
パネル
-------------------------- */

.c-panel {
  background-color: $color-blue-light;

  @include view-at(sp) {
    margin: (-1) * $contents-padding-sp;
  }

  &--shadow {
    background-color: $color-white;
    box-shadow: 0 3px 6px rgba(0,0,0,0.31);
  }

  &--important {
    border: 2px solid $color-red-dark;
    background-color: $color-white;
  }

  &--rounded {
    border-radius: 8px;
  }

  &-contents {
    padding: 60px 65px;

    @include view-at(sp) {
      padding: 30px;
    }

    &--narrow {
      padding: 15px;
    }
  }

  &-h {
    font-size: rem(18px);
    line-height: rem(32px);
    font-weight: $bold;
    padding-bottom: 6px;
    border-bottom: 1px solid $color-blue;
    margin-bottom: 25px;
  }

  &-body {
    padding: 0 30px;

    @include view-at(sp) {
      padding: 0;
    }
  }

  &-section {
    background-color: $color-blue-light;

    @include view-at(sp) {
      margin: (-1) * $contents-padding-sp;
    }

    &-h {
      color: $color-white;
      font-size: rem(22px);
      text-align: center;
      font-weight: $bold;
      height: 54px;
      line-height: 54px;
      padding: 0 30px;
      background: linear-gradient(90deg, #008ddd 0%, #004d8f 100%);
      letter-spacing: 0.2em;
    }

    &-contents {
      padding: 40px 65px 80px;

      @include view-at(sp) {
        padding: 30px;
      }
    }
  }
}
