/* --------------------------
サブナビ
-------------------------- */

.p-subnav {
  &-list {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0 -20px;

    @include view-at(tab) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &-item {
    width: 100%;
    margin: 0 20px;

    @include view-at(tab) {
      width: 50%;
      margin: 0;
      padding: 0 20px;
    }

    @include view-at(sp) {
      width: 100%;
    }

    &:not(:last-child) {
      @include view-at(tab) {
        margin-bottom: 40px;
      }

      @include view-at(sp) {
        margin-bottom: 20px;
      }
    }

    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 200px;
      color: $color-white;
      font-size: rem(16px);
      line-height: 1.5;
      text-align: center;
      letter-spacing: -1px;
      padding: 4px;
      position: relative;

      @include view-at(tab) {
        flex-direction: row;
        flex-wrap: wrap;
        height: auto;
      }

      img {
        height: 60px;
        margin: 10px 0 20px 0;
  
        @include view-at(tab) {
          height: 50px;
          margin: 10px 16px 10px 0;
        }
      }

      .sp-only {
        display: none;

        @include view-at(sp) {
          display: block;
        }
      }
    }

    &.current {
      a {
        font-weight: $bold;
        pointer-events: none;

        &::after {
          display: block;
          content: '';
          width: calc(100% - 8px);
          height: 4px;
          background-color: $color-white;
          position: absolute;
          left: 4px;
          bottom: 4px;
        }
      }
    }

    @include view-at(pc) {
      &:hover {
        a {
          &::after {
            display: block;
            content: '';
            width: calc(100% - 8px);
            height: 4px;
            background-color: $color-white;
            position: absolute;
            left: 4px;
            bottom: 4px;
          }
        }
      }
    }

    &--blue {
      background-color: $color-blue;
    }
    &--blue-dark {
      background-color: $color-blue-dark;
    }

    &--point {
      position: absolute;
      top: 4px;
      left: 4px;
      display: inline-block;
      color: $color-red-dark;
      font-size: rem(16px);
      font-weight: $bold;
      line-height: 1;
      letter-spacing: normal;
      padding: 4px;
      background-color: $color-white;

      @include view-at(tab) {
        position: static;
        width: 100%;
      }
    }
  }
}