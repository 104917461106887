/* --------------------------
フッター
-------------------------- */

.p-footer {
  background-color: $color-white;
  padding: 0;

  @include view-at(tab) {
    margin-bottom: 47px;
  }

  &-pagetop {
    position: absolute;
    top: 0;
    right: 0;
    width: 58px;
    height: 58px;

    @include view-at(tab) {
      top: -78px;
      right: 15px;
      width: 40px;
      height: 40px;
    }
  }

  &-container {
    width: $contents-width;
    margin: 0 auto;
    position: relative;

    @include view-at(pc) {
      padding: 0 $contents-padding;
    }

    @include view-at(tab) {
      width: 100%;
      padding: 0 40px;
    }

    @include view-at(sp) {
      width: 100%;
      padding: 0;
    }
  }

  &-contents {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
    letter-spacing: 0;

    @include view-at(tab) {
      display: block;
    }

    &-left {
      width: 66.6666%;

      @include view-at(tab) {
        width: 100%;
      }
    }

    &-right {
      width: 33.3333%;
      border-left: 0.5px solid #808080;
      padding-left: 50px;
      padding-bottom: 80px;

      @include view-at(tab) {
        width: 100%;
        border: 0;
        padding: 0;
      }
    }
  }

  &-section {
    margin: 20px 0;

    @include view-at(tab) {
      margin: 0;
    }
  }

  &-h3 {
    color: $color-blue;
    font-size: rem(23px);
    font-weight: $bold;
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    @include view-at(tab) {
      width: 100%;
      padding: 17px 12px;
      margin-bottom: 0;
      border-bottom: 1px solid $color-border;
      position: relative;
      font-size: rem(20px);

      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 23px;
        right: 15px;
        font-weight: $normal;
        width: 23px;
        height: 23px;
        border-radius: 50%;
        background-color: $color-blue;
      }

      &::after {
        content: '＋';
        display: inline-block;
        position: absolute;
        top: 23px;
        right: 15px;
        font-weight: $normal;
        color: $color-white;
      }

      &.active::after {
        content: '−';
        display: inline-block;
        position: absolute;
        top: 23px;
        right: 15px;
        font-weight: $normal;
      }
    }
  }

  &-h4 {
    font-size: rem(19px);
    line-height: rem(33px);
    font-weight: $bold;
    position: relative;
    padding-left: 20px;
    margin-bottom: 12px;

    @include view-at(tab) {
      font-size: rem(18px);
      line-height: rem(22px);
      padding: 15px;
      margin: 0;
      border-bottom: 1px solid $color-border;
    }

    &::before {
      content: '';
      width: 10px;
      height: 10px;
      border-top: 1px solid $color-blue;
      border-right: 1px solid $color-blue;
      transform: rotate(45deg);
      display: inline-block;
      position: absolute;
      top: 10px;
      left: 0;

      @include view-at(tab) {
        display: none;
      }
    }
  }

  &-icon {
    width: 37px;
    margin-right: 5px;
  }

  &-banner {
    padding: 40px 0;
    background: $color-blue-light;

    &-contents {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      letter-spacing: 0;
      margin-bottom: 0;

      @include view-at(sp) {
        display: block;
      }

      div {
        padding: 0 20px;
        text-align: center;

        @include view-at(sp) {
          &:not(:first-child) {
            margin-top: 20px;
          }
        }
      }

      button {
        padding: 0;
        border: none;
        cursor: pointer;
        background-color: transparent;

        &:hover {
          opacity: 0.6;
        }
      }
    }
  }

  &-company {
    color: $color-white;
    padding: 40px 0;
    background: $color-blue;

    @include view-at(sp) {
      padding: 35px 20px;
    }

    &-contents {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @include view-at(tab) {
        justify-content: center;
      }

      @include view-at(sp) {
        display: block;
      }
    }
  }

  &-nav {
    width: 38%;

    @include view-at(tab) {
      display: none;

      &.visible {
        display: block;
      }
    }

    @include view-at(sp) {
      width: 100%;
    }
  }

  &-list {
    margin-bottom: 25px;
    
    @include view-at(tab) {
      margin: 0;
    }

    > li {
      font-size: rem(14px);
      line-height: rem(20px);
      font-weight: $bold;
      position: relative;
      padding-left: 15px;
      margin-bottom: 20px;

      @include view-at(tab) {
        padding: 0;
        margin: 0;
        font-weight: $normal;
        border-bottom: 1px solid $color-border;
      }

      &::before {
        content: '';
        width: 7px;
        height: 7px;
        border-top: 1px solid $color-blue;
        border-right: 1px solid $color-blue;
        transform: rotate(45deg);
        display: inline-block;
        position: absolute;
        top: 6.5px;
        left: 0;
        transition: all 0.3s;

        @include view-at(tab) {
          display: none;
        }
      }

      > a {
        color: $color-text;

        @include view-at(tab) {
          display: block;
          padding: 15px;

          &::after {
            content: '';
            width: 8px;
            height: 8px;
            border-top: 1px solid $color-gray-dark;
            border-right: 1px solid $color-gray-dark;
            transform: rotate(45deg);
            display: inline-block;
            position: absolute;
            top: calc(50% - 4px);
            right: 20px;
          }
        }
      }
    }

    &-internal {
      margin-left: -15px;
      margin-top: 11.5px;

      @include view-at(tab) {
        margin: 0;
      }

      >li {
        font-size: rem(12px);
        line-height: rem(20px);
        position: relative;
        padding-left: 15px;
        margin-bottom: 10px;

        @include view-at(tab) {
          padding: 0;
          margin: 0;
          border-bottom: 1px solid $color-border;
          font-size: rem(14px);
        }

        &::before {
          content: '';
          width: 7px;
          height: 7px;
          border-top: 1px solid $color-blue;
          border-right: 1px solid $color-blue;
          transform: rotate(45deg);
          display: inline-block;
          position: absolute;
          top: 6.5px;
          left: 0;
          transition: all 0.3s;

          @include view-at(tab) {
            display: none;
          }
        }

        &:hover {
          &::before {
            border-color: $color-blue-dark;
            transform: rotate(45deg) translate(2px, -2px);
          }
        }

        a {
          color: #484B44;
          font-weight: $normal;

          @include view-at(tab) {
            display: block;
            padding: 15px;

            &::after {
              content: '';
              width: 8px;
              height: 8px;
              border-top: 1px solid $color-gray-dark;
              border-right: 1px solid $color-gray-dark;
              transform: rotate(45deg);
              display: inline-block;
              position: absolute;
              top: calc(50% - 4px);
              right: 20px;
            }
          }
        }
      }
    }
  }

  &-link {
    width: 25%;
    padding: 15px;

    @include view-at(tab) {
      width: auto;
      padding: 0 15px;
    }

    p {
      font-size: rem(15px);
      line-height: 1.8;
      letter-spacing: normal;
      margin-top: 1em;

      @include view-at(sp) {
        font-size: rem(14px);
      }
    }
  }

  &-logo {
    width: 31%;
    padding: 15px;

    @include view-at(tab) {
      width: auto;
      padding: 0 15px;
    }
    
    @include view-at(sp) {
      margin-bottom: 30px;
    }

    &--text {
      font-size: rem(15px);
      line-height: 1.8;
      letter-spacing: normal;
      margin-top: 1em;

      @include view-at(sp) {
        font-size: rem(14px);
      }

      span {
        font-size: rem(20px);
        font-weight: $bold;

        @include view-at(sp) {
          font-size: rem(18px);
        }
      }
    }
  }

  &-loan {
    @include view-at(sp) {
      border: 1px solid $color-gray;
      padding: 15px;
      margin-bottom: 20px;
    }


    &-icon {
      width: 156px;
      float: left;
      margin-right: 20px;

      @include view-at(tab) {
        width: 200px;
      }

      @include view-at(sp) {
        float: none;
        width: 100%;

        img {
          width: 100%;
        }
      }
    }

    &-description {
      font-size: rem(10px);
      line-height: rem(18px);
    }
  }

  &-copyright {
    font-size: rem(12px);
    line-height: 1;
    color: $color-text;
    background-color: $color-white;
    padding: 20px;
    text-align: center;

    @include view-at(tab) {
      font-size: rem(9px);
      line-height: rem(13px);
      padding: 14px;
    }
  }
}
