/* --------------------------
サイドナビ
-------------------------- */

.p-sidebar {
  flex-basis: 360px;
  flex-shrink: 0;
  padding: 0;
  margin-left: 60px;

  @include view-at(sp) {
    margin-left: 0;
  }

  &-contents {
    background-color: $color-white;
    padding: 1px 30px;

    @include view-at(sp) {
      padding: 1px 20px;
    }

    p {
      font-size: rem(14px);
    }
  }

  &-title {
    font-size: rem(20px);
    color: $color-green;
    font-weight: $bold;
    margin-bottom: 15px;
  }

  &-list {
    li {
      border-bottom: 1px solid $color-gray-light;

      a {
        display: block;
        padding: 10px;
        text-decoration: none;
      }
    }
  }
}
