/* --------------------------
ヘッダー
-------------------------- */

.p-header {
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  background-color: $color-white;
  width: 100%;

  @include view-at(pc) {
    min-width: $contents-width;
  }
  
  @include view-at(tab) {
    position: fixed;
    min-width: auto;
    box-shadow: 0 3px 6px rgba(0,0,0,0.1);
  }

  &-right {
    @include view-at(tab) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  
  &-inner {
    background-color: rgba(255,255,255,0.75);
    max-width: 1140px;
    margin: 0 auto;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include view-at(tab) {
      display: block;
      padding: 0;
    }
  }

  &-logo {
    text-align: left;
    font-size: 0;

    @include view-at(tab) {
      padding: 13px 15px;
    }

    a {
      display: inline-block;
      line-height: 1;
      font-size: 0;

      @include view-at(pc) {
        img {
          width: auto;
          height: 45px;
        }
      }

      @include view-at(tab) {
        img {
          width: auto;
          height: 24px;
        }
      }
    }
  }

  &-contact {
    display: flex;
    align-items: center;
    
    &-button {
      display: none;;

      @include view-at(tab) {
        width: 100%;
      }

      &--recruit {
        @include view-at(tab) {
          background-color: $color-orange;
          display: flex;
          flex-direction: column;
          width: 154px;
          height: 65px;
          color: $color-white;
          font-size: rem(15px);
          text-align: center;
          align-items: center;
          justify-content: center;
          transition: all 0.3s;
          border: 1px solid $color-orange;
        }

        @include view-at(tab) {
          margin-left: 0;
          width: 100%;
          flex-direction: row;
          height: 47px;
        }

        &:hover {
          background-color: $color-white;
          color: $color-orange;
          opacity: 1;

          &::before {
            background-image: url(../images/common/icon-reserve-inverse.png);
          }
        }

        &::before {
          content: '';
          display: block;
          width: 23px;
          height: 21px;
          margin-bottom: 5px;
          background: transparent url(../images/common/icon-reserve.png) center center no-repeat;
          background-size: contain;

          @include view-at(tab) {
            margin-bottom: 0;
            margin-right: 10px;
          }
        }
      } 

      &--tel {
        @include view-at(tab) {
          background-color: $color-navy;
          display: flex;
          flex-direction: column;
          width: 154px;
          height: 65px;
          color: $color-white;
          font-size: rem(15px);
          text-align: center;
          align-items: center;
          justify-content: center;
          transition: all 0.3s;
          border: 1px solid $color-navy;
        }

        @include view-at(tab) {
          margin-left: 0;
          width: 100%;
          flex-direction: row;
          height: 47px;
        }

        &:hover {
          background-color: $color-white;
          color: $color-navy;
          opacity: 1;

          &::before {
            background-image: url(../images/common/icon-tel-inverse.png);
          }
        }

        &::before {
          content: '';
          display: block;
          width: 23px;
          height: 21px;
          margin-bottom: 5px;
          background: transparent url(../images/common/icon-tel.png) center center no-repeat;
          background-size: contain;

          @include view-at(tab) {
            margin-bottom: 0;
            margin-right: 10px;
          }
        }
      } 
    }
  }
  &-recruit {

    @include view-at(tab) {
      display: none;
    }

    img {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
      transition: .3s;
    }

    a {
      &:hover {
        opacity: 1;

        img {
          box-shadow: none;
          transform: translate3d(0, 3px, 0);
        }
      }
    }
  }
  &-tel {
    margin-left: 40px;

    @include view-at(tab) {
      display: none;
    }

    &-icon {
      width: 28px;
    }

    &-text {
      color: $color-black;
      font-size: rem(16px);
      font-weight: $medium;
      text-align: right;
      letter-spacing: -0.1px;
      margin-top: 8px;

      span {
        color: $color-white;
        padding: 0 4px 1px 3px;
        margin-right: 10px;
        background-color: $color-orange;
      }
    }

    a {
      color: $color-black;
      font-size: rem(30px);
      font-weight: $bold;
      text-decoration: none;
    }
  }
}
