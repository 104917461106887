.h1-service {
  background-image: url(../images/service/h1.jpg);
}

.service {
  &-h2 {
    margin-top: -20px;
    padding-top: 20px;
    z-index: -1;

    @include view-at(tab) {
      margin-top: -60px;
      padding-top: 60px;
    }

    br {
      display: none;

      @include view-at(sp) {
        display: block;
      }
    }
  }
}