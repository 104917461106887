/* --------------------------
フッター
-------------------------- */

.p-fnav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &-column {
    padding: 15px;
  }

  &-list {
    >li {
      margin-bottom: 12px;

      a {
        position: relative;
        color: $color-white;
        text-decoration: none;
        font-size: rem(15px);
        font-weight: $medium;
        padding-left: 1em;

        &::before {
          position: absolute;
          top: 5px;
          left: 0;
          display: inline-block;
          content: '>';
          width: 10px;
          height: 16px;
          /* border-top: 1px solid $color-white;
          border-right: 1px solid $color-white;
          transform: rotate(45deg); */
    
          @include view-at(tab) {
            display: none;
          }
        }

        &:hover {
          color: $color-white;
          opacity: 0.6;
        }
      }
    }

    &-sub {
      >li {
        margin-top: 15px;
        color: $color-white;
        text-decoration: none;
        font-size: 0.8rem;
        font-weight: $regular;
        letter-spacing: 0.08em;
        
        &::before {
          content: '-';
        }
      }

      &--horizontal {
        display: flex;
        flex-wrap: wrap;
        max-width: 355px;

        >li {
          &:nth-child(2n) {
            width: 115px;
          }
          &:nth-child(2n-1) {
            width: 240px;
          }
        }
      }
    }
  }
}
