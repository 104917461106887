/* --------------------------
ボタン
-------------------------- */

.c-block {
  display: inline-block;
  width: 100%;
  color: $color-white;
  font-size: rem(17px);
  line-height: 1.4;
  text-align: center;
  padding: 15px 5px;
  border-radius: 4px;

  @include view-at(tab) {
    text-align: left;
    padding: 15px;
  }

  @include view-at(sp) {
    font-size: rem(15px);
    padding: 10px 15px;
  }

  &--large {
    font-size: rem(24px);
    padding: 44px 5px;

    @include view-at(tab) {
      text-align: left;
      padding: 44px 15px;
    }

    @include view-at(sp) {
      font-size: rem(20px);
      padding: 20px 15px;
    }
  }
}
