@include view-at(sp) {
    .order-1--sp { order: 1; }
    .order-2--sp { order: 2; }
    .order-3--sp { order: 3; }
}

.news {
    &-category {
        margin-bottom: 60px;

        @include view-at(sp) {
            margin-bottom: 40px;
        }

        &-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        &-items {
            position: relative;
            margin: 0 20px;

            @include view-at(sp) {
                width: 29%;
                margin: 3% 2%;
            }

            a {
                display: inline-block;
                width: 100%;
                color: $color-text-light;
                text-align: center;
                padding-bottom: 20px;

                @include view-at(sp) {
                    padding-bottom: 10px;
                }

                &::before {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    display: inline-block;
                    content: '';
                    width: 100%;
                    border-bottom: 5px solid;
                }
            }
        }

        &--select {
            a {
                color: $color-text;
            }
        }

        &--all {
            a::before {
                border-color: $color-text;
            }
        }
        &--notice {
            a::before {
                border-color: $color-blue;
            }
        }
        &--important {
            a::before {
                border-color: $color-red;
            }
        }
        &--seminar {
            a::before {
                border-color: $color-green;
            }
        }
        &--recruit {
            a::before {
                border-color: $color-orange;
            }
        }
    }

    &-list {
        a {
            color: $color-text;
        }
    }
}