
.wp-pagenavi{
  text-align: center;
  margin-top: 80px;
  @include view-at(sp) {
    margin-top: 50px;
  }
  .pages{
    display: none;
  }
  span, a{
    /*数字部分の共通CSS　大きさなど*/
    display: inline-block;
    margin: 0 5px;
    padding: 7px 10px;
    color: $color-text-light;
    text-decoration: none;
    border: 1px solid $color-text-light;

    &.current, &:hover{
      /*現在のページ*/
      color: $color-text;
      border: 1px solid $color-text;
    }

    &.nextpostslink, &.previouspostslink {
      font-size: 24px;
      border: 1px solid $color-white;

      &:hover {
        border: 1px solid $color-white;
      }
    }
  }
}
