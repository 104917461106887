.h1-technology {
  background-image: url(../images/technology/h1.jpg);
}

.technology {
  &-h2 {
    br {
      display: none;

      @include view-at(sp) {
        display: block;
      }
    }
  }

  &-arrow {
    position: relative;
    padding-bottom: 1.5rem;

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      left: calc(50% - 4px);
      bottom: 0;
      width: 18px;
      height: 18px;
      border-top: 4px solid $color-orange;
      border-right: 4px solid $color-orange;
      transform: rotate(135deg);
    }
  }
}
